import React from 'react';
import '../styles/Resume.css';

function Resume() {
    return (
        <section className='resume'>
            <h2>Resume</h2>
            <div className='pdf-viewer'>
                <iframe
                    src='assets/resume/AMNResume2025-1.pdf'
                    width='100%'
                    height='1000px'
                    title='Resume PDF'
                ></iframe>
            </div>
            <a
                href='assets/resume/AMNResume2025-1.pdf'
                download
                className='resume-download'
            >
                Download Resume
            </a>
            {/* Add more content as needed */}
        </section>
    );
}

export default Resume;
